import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Article from '@components/pages/article/index';

const ArticlePage = props => {
  const [article, setArticle] = useState(null);
  const {
    site: {
      siteMetadata: { articles },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            articles {
              image
              title
              featuredTitle
              content
              link
            }
          }
        }
      }
    `
  );

  useEffect(() => {
    if (!articles) {
      return;
    }    
    const _article = articles.find(
      item => process.env.BaseURL + item.link === props.location.pathname
      );
    setArticle(_article);
  }, [props.location.pathname, articles]);

  return (
    <Layout {...props}>
      <SEO title={article?.title ? `${article.title} | Articles` : 'Articles'} />
      {article && <Article
        image={article.image}
        title={article.title}
        featuredTitle={article.featuredTitle}
        content={article.content}
      />}
    </Layout>
  );
};

export default ArticlePage;
